var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reputation-triggers-settings"},[_c('a-alert',[_c('extended-help-message',{attrs:{"slot":"message","message":_vm.$t('reputation_extended_triggers_page_description'),"items":{
        'extended_triggers_more': _vm.$t('reputation_extended_triggers_page_help_message')
      }},slot:"message"})],1),(_vm.getTagsByFieldKey("extended_reputation"))?_c('not-available-options-overlay',{staticClass:"relative mt-5",attrs:{"video":_vm.$i18n.locale !== 'ru' ?
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_Advanced reputation settings_en.m4v' :
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_Advanced reputation settings_ru.m4v.m4v',"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.getTagsByFieldKey('extended_reputation')}}):[_c('div',{staticClass:"flex justify-start sm:justify-end my-5"},[_c('a-button',{staticClass:"btn-wrap",attrs:{"type":"primary","icon":"plus","disabled":_vm.disabled},on:{"click":_vm.createTrigger}},[_vm._v(" "+_vm._s(_vm.$t('reputation_triggers_create_new'))+" ")])],1),(_vm.frontendReputationTriggers.length > 0)?_vm._l((_vm.frontendReputationTriggers),function(item){return _c('reputation-trigger-card',{key:item.guid,attrs:{"item":item.value},on:{"delete":function($event){return _vm.deleteTrigger(item.guid)},"edit":function($event){return _vm.editTrigger(item)}}})}):_c('empty-data',{staticClass:"mt-5",attrs:{"description":"reputation_triggers_list_empty"}}),_c('a-modal',{staticClass:"modal-overflow-visible",attrs:{"ok-text":_vm.$t('pi_accept'),"cancel-text":_vm.$t('pi_reject'),"after-close":function () { return _vm.activeTrigger = null; }},on:{"ok":_vm.saveTrigger},model:{value:(_vm.isModalOpen),callback:function ($$v) {_vm.isModalOpen=$$v},expression:"isModalOpen"}},[(_vm.activeTrigger !== null)?_c('div',[_c('typed-chips-input',{staticClass:"mt-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'triggers',
              'prefix': 'reputation_trigger_',
              'possibleChipTypeInfos': _vm.REPUTATION_TYPES,
              'validation': 'required',
              'disabled': _vm.disabled
            }
          }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': Object.assign({}, {'model': _vm.activeTrigger.value,
              'key': 'message',
              'prefix': 'reputation_trigger_',
              'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
              'targetToUpload': _vm.groupUploadTarget,
              'buttonsHelpView': _vm.EditorButtonsHelpView,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
              'blacklistMediaButtonTypes': _vm.blacklistedFileButtonsByLicense,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'settingsStructure': _vm.defaultMessageEditorSettingsStructure,
              'options': {
                topics: _vm.topicsAvailable
              }},
              _vm.topicsIfForum,
              {'hasMedia': true,
              'disabled': _vm.disabled}),
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'min',
              'prefix': 'reputation_trigger_',
              'validation': 'required',
              'disabled': _vm.disabled
            }
          }}}),_c('number-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'max',
              'prefix': 'reputation_trigger_',
              'validation': 'required',
              'disabled': _vm.disabled
            }
          }}}),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'actor_type',
              'prefix': 'reputation_trigger_',
              'options': _vm.actorTypeOptions,
              'clearable': false,
              'disabled': _vm.disabled
            }
          }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'target_type',
              'prefix': 'reputation_trigger_',
              'options': _vm.actorTypeOptions,
              'clearable': false,
              'disabled': _vm.disabled
            }
          }}}),_c('a-divider'),_c('select-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'actor_reaction_emoji',
              'prefix': 'positive_',
              'options': _vm.emojiOptions,
              'settingWrapper': {
                'customiseValue': _vm.emojiOptions[0],
                'value': { value: null },
              },
              'disabled': _vm.disabled
            }
          }}}),_c('select-input',{staticClass:"mb-0",attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.activeTrigger.value,
              'key': 'target_reaction_emoji',
              'prefix': 'positive_',
              'options': _vm.emojiOptions,
              'settingWrapper': {
                'customiseValue': _vm.emojiOptions[0],
                'value': { value: null },
              },
              'disabled': _vm.disabled
            }
          }}})],1):_vm._e()])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }