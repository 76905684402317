var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extended-reputation-settings"},[_c('a-alert',[_c('extended-help-message',{attrs:{"slot":"message","message":_vm.$t('reputation_extended_page_description'),"items":{
        'extended_reputation_more': _vm.$t('reputation_extended_page_help_message')
      }},slot:"message"})],1),(_vm.getTagsByFieldKey("extended_reputation"))?_c('not-available-options-overlay',{staticClass:"relative mt-5",attrs:{"video":_vm.$i18n.locale !== 'ru' ?
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_Advanced reputation settings_en.m4v' :
      'https://client.chatkeeper.app/assets/video/video_ChatKeeperBot_Advanced reputation settings_ru.m4v.m4v',"description":_vm.$t("option_description_extended_reputation"),"tags":_vm.getTagsByFieldKey('extended_reputation')}}):_c('a-card',{staticClass:"mt-5"},[_c('select-input',{staticClass:"mt-0",attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'reputation_mode',
          'options': _vm.reputationModeOptions,
          'disabled': !(_vm.module.model.params.positive.enabled || _vm.module.model.params.negative.enabled) || _vm.disabled,
          'hasAnchor': true,
          'clearable': false
        }
      }}}),(_vm.module.model.params.reputation_mode === _vm.ReputationModeEnum.Separated || _vm.module.model.params.reputation_mode === _vm.ReputationModeEnum.Admins)?_c('nested-content',[(_vm.module.model.params.reputation_mode === _vm.ReputationModeEnum.Separated)?[(_vm.module.model.params.positive.enabled)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module,
              'key': 'positiveRepAdminMessage',
              'buttonsHelpView': _vm.EditorButtonsHelpView,
              'targetToUpload': _vm.groupUploadTarget,
              'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
              'tariffTags': _vm.getTagsByFieldKey("extended_reputation"),
              'hasMedia': true,
              'disabled': _vm.disabled
            },
          }}}):_vm._e(),(_vm.module.model.params.negative.enabled)?_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.newConfigInputSetup,
            'args': {
              'model': _vm.module,
              'key': 'negativeRepAdminMessage',
              'buttonsHelpView': _vm.EditorButtonsHelpView,
              'targetToUpload': _vm.groupUploadTarget,
              'placeholders': _vm.SPEC_REP_TRIG_DEF_PLACEHOLDERS,
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'availableButtonsTypes': _vm.newDefaultMessageEditorButtons,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
              'hasMedia': true,
              'disabled': _vm.disabled
            },
          }}}):_vm._e()]:_vm._e(),_c('switch-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params,
            'key': 'allow_reputation_only_from_whitelist',
            'disabled': !(_vm.module.model.params.positive.enabled || _vm.module.model.params.negative.enabled) || _vm.disabled,
            'hasAnchor': true,
          },
        }}}),(_vm.module.model.params.allow_reputation_only_from_whitelist)?_c('nested-content',[_c('highlight-anchor',{staticClass:"my-3 py-1",attrs:{"has-anchor-icon":"","anchor-key":"reputationUsersWhitelist"}},[_c('config-field',{staticClass:"mx-1",attrs:{"title":_vm.$t('field_reputation_users_whitelist_title'),"mini-help-message":_vm.$t('field_reputation_users_whitelist_mini_help_message')}},[_c('add-admin',{attrs:{"add-type":_vm.AddAdminType.Reputation,"module-guid":_vm.module.model.guid,"add-users-disabled":(_vm.ModuleManagerState && !_vm.ModuleManagerState.isCurrentModuleSaved),"disabled":_vm.disabled,"users-path":{
                model: _vm.module.model.params,
                key: 'reputation_users_whitelist'
              }}})],1)],1)],1):_vm._e()],2):_vm._e(),_c('select-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module,
          'key': 'repCalcMode',
          'options': _vm.reputationCalculationModeOptions,
          'disabled': !(_vm.module.model.params.positive.enabled || _vm.module.model.params.negative.enabled) || _vm.disabled,
          'hasAnchor': true,
          'clearable': false
        }
      }}}),(_vm.module.model.params.reputation_calculation_mode instanceof _vm.RandomReputationCalculationMode)?_c('nested-content',[_c('number-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params.reputation_calculation_mode,
            'key': 'min',
            'prefix': 'reputation_random_calculation_',
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}}),_c('number-input',{attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': _vm.module.model.params.reputation_calculation_mode,
            'key': 'max',
            'prefix': 'reputation_random_calculation_',
            'min': _vm.module.model.params.reputation_calculation_mode.min || 0,
            'hasAnchor': true,
            'disabled': _vm.disabled
          }
        }}})],1):_vm._e(),(_vm.module.model.params.reputation_calculation_mode instanceof _vm.ComplexReputationCalculationMode)?_c('nested-content',[_c('number-input',{staticClass:"mt-0 mb-0",attrs:{"setup":{
          'func': _vm.newConfigInputSetup,
          'args': {
            'model': this,
            'key': 'multiplier',
            'prefix': 'reputation_complex_calculation_',
            'hasAnchor': true,
            'min': 0,
            'max': 100,
            'step': 0.1,
            'disabled': _vm.disabled
          }
        }}})],1):_vm._e(),_c('switch-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'reputation_with_mention',
          'disabled': !(_vm.module.model.params.positive.enabled || _vm.module.model.params.negative.enabled) || _vm.disabled,
          'hasAnchor': true,
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.newConfigInputSetup,
        'args': {
          'model': _vm.module.model.params,
          'key': 'reputation_overtake_message',
          'buttonsHelpView': _vm.EditorButtonsHelpView,
          'placeholders': _vm.SPEC_REP_TRIG_OVR_PLACEHOLDERS,
          'targetToUpload': _vm.groupUploadTarget,
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
          'hasMedia': true,
          'disabled': _vm.disabled
        },
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }